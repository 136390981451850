<template>
  <b-card
      no-body
      class="mb-0 "
  >
    <titulo-card-com-voltar
        voltar="projetos"
        titulo="Lista de Comentários do Projeto"
    />
    <b-card
        cols="12"
        class=" px-2"
    >
      <b-form-group
          label="Novo Comentário"
          label-for="comentario"
          class="mb-2"
      >
        <quill-editor
            id="comentario"
            ref="quillEditorRef"
            v-model="itemData.comentario"
            :options="snowOption"
        />

        <div class="d-flex justify-content-end mt-1">
          <b-button
              variant="success"
              @click="enviarComentario"
          >
            <feather-icon
                icon="SendIcon"
                class="mr-50"
            />
            <span class="align-middle">Enviar</span>
          </b-button>
        </div>

      </b-form-group>
      <b-row>
        <b-col
            cols="12"
            class="mb-1"
        >
          <h4 v-if="listaComentarios.length">
            Comentários do projeto: {{ listaComentarios[0].comentavel.descricao }}
          </h4>
        </b-col>
        <b-col
            v-for="(item, index) in listaComentarios"
            :key="item.id"
            cols="12"
        >
          <b-media
              no-body
              class="border p-2 mb-1 rounded"
          >
            <b-media-aside>

              <b-avatar
                  v-if="item.usuario"
                  size="50"
                  :src="item.usuario.imagem"
                  :text="avatarText(item.usuario.name)"
              />

            </b-media-aside>
            <b-media-body>
              <div class="d-flex justify-content-between pb-2">
                <div>
                  <h6>
                    <span class="font-weight-bolder">{{ item.usuario && item.usuario.name }} </span> <span
                      v-if="item.usuario && item.usuario.funcao"
                      class="font-weight-light"
                  >[{{ item.usuario && item.usuario.funcao }}]</span>
                  </h6>
                  <small>{{ mascaraDataBr(item.created_at) }}</small>
                </div>
                <div
                    v-if="$can('excluir', 'comentarios' )"
                    class="d-flex justify-content-end"
                >
                  <b-button
                      variant="link"

                      size="sm"
                      @click="modalExcluir(item)"
                  >
                    <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="text-danger"
                    />
                  </b-button>
                </div>
              </div>

              <b-card-text
                  class="bg-light rounded p-1 font-size-9 descricao-comentario"
                  v-html="processarComentario(item.comentario,item.imagens)"
                  @click="handleImageClick"
              />
              <div class="d-flex justify-content-between">
                <div>
                  <b-button variant="light" v-if="item.imagens.length" @click="visualizarFoto(item.imagens)">
                    <FeatherIcon class="mr-1" icon="ImageIcon"></FeatherIcon>
                    <span>{{ item.imagens.length }}</span>
                  </b-button>
                </div>
                <b-button variant="primary" v-b-tooltip.hover.top="`Responder comentário`"
                          @click="abrirModalResponder(item)"
                >
                  <FeatherIcon icon="CornerUpLeftIcon"></FeatherIcon>
                </b-button>
              </div>
              <div v-if="item.comentarios.length" v-for="subItem in item.comentarios" :key="subItem.id">
                <div class="d-flex ml-5 mt-2">

                  <div class="font-size-8 d-flex align-items-center">
                    <b-avatar
                        v-if="subItem.usuario"
                        size="30"
                        :src="subItem.usuario.imagem"
                        :text="avatarText(subItem.usuario.name)"
                        class="mr-1"
                    />
                    <div class="d-flex flex-column">
                      <div> {{ subItem.usuario.name }}</div>
                      <div>{{ formatarDataEHora(subItem.created_at) }}</div>
                    </div>
                  </div>
                </div>

                <b-card-text
                    v-if="subItem"
                    class="bg-light rounded mt-1 ml-5 p-1 font-size-9 descricao-comentario"
                    v-html="processarComentario(subItem.comentario, subItem.imagens)"
                    @click="handleImageClick"
                />
                <div>
                  <b-button class="ml-5" variant="light" v-if="subItem && subItem.imagens.length"
                            @click="visualizarFoto(subItem.imagens)"
                  >

                    <FeatherIcon class="mr-1" icon="ImageIcon"></FeatherIcon>
                    <span>{{ subItem.imagens.length }}</span>
                  </b-button>
                </div>

              </div>

            </b-media-body>
          </b-media>

        </b-col>
      </b-row>

    </b-card>
    <b-modal
        id="imagem-modal"
        size="lg"
        hide-footer
        class="modal__comentarios"
    >

      <b-carousel
          id="carousel-1"
          ref="myCarousel"
          :interval="60000"
          img-width="524"
          img-height="380"

      >
        <b-carousel-slide v-for="(img,index) in imagensCarousel"
                          :key="index"
        >
          <template #img>
            <image-zoom
                :hover-message="'Clique para ampliar'"
                :touch-message="'Clique para ampliar'"
                :zoom-amount="3"
                :click-zoom="true"
                img-class="img-fluid"
                :regular="img.url"
                :zoom="img.url"
            >
            </image-zoom>
          </template>
        </b-carousel-slide>

      </b-carousel>

      <div class="d-flex align-items-center justify-content-center mt-1" v-if="imagensCarousel.length > 1">
        <b-button-group>
          <b-button
              ref="btnPrev"
              variant="outline-primary"
              @click="prev"
          >
            Anterior
          </b-button>

          <b-button
              variant="outline-primary"
              ref="btnNext"
              @click="next"
          >
            Próxima
          </b-button>
        </b-button-group>
      </div>

    </b-modal>
    <b-modal
        id="modal-selecionar-usuarios"
        ref="ModalSelecionarUsuarios"
        size="sm"
        class="modalSelecionarUsuarios"
        hide-footer
    >
      <template #modal-title>
        <h6 class="modal-title">Selecione o usuário da engenharia</h6>
      </template>

      <select-com-pesquisa
          placeholder="Escolha o usuário"
          @selecionados="tratarEventoUsuarioSelecionado"
          :itens-para-edicao="[]"
          url="users?noRole=true"
      ></select-com-pesquisa>

    </b-modal>
    <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir o comentário do usuário"
        text-secondary="Essa ação não poderá ser desfeita."
        :item-id="dadosItem.id"
        :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem"
    />
    <b-modal
        id="modal-responder"
        ref="modalResponder"
        size="lg"
        class="modalSelecionarUsuarios"
    >
      <template #modal-title>
        <h6 class="modal-title">Responder comentário</h6>
      </template>

      <quill-editor
          id="comentario"
          ref="quillEditorRef"
          v-model="respostaComentario.comentario"
          :options="snowOption"
      />

      <template #modal-footer>
        <b-button
            variant="light"
            @click="$bvModal.hide('modal-responder')"
        >Cancelar
        </b-button>
        <b-button
            variant="success"
            @click="enviarResposta"
        >
          <feather-icon
              icon="SendIcon"
              class="mr-50"
          />
          <span class="align-middle">Enviar</span>
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { avatarText, convertStringToDate, formatarDataEHora, mascaraDataBr } from '@core/utils/filter'
import {
  BAvatar,
  BButton,
  BButtonGroup,
  BCard,
  BCardText,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { getCurrentInstance, onMounted, onUnmounted, ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { useToast } from 'vue-toastification/composition'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import router from '@/router'
import store from '@/store'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import imageZoom from 'vue-image-zoomer'
import userStoreModule from '../comentarioStoreModule'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'

export default {
  components: {
    SelectComPesquisa,
    BCardText,
    BButtonGroup,
    BMediaBody,
    BMediaAside,
    BMedia,
    BAvatar,
    TituloCardComVoltar,
    BFormGroup,
    BCol,
    BCard,
    quillEditor,
    BButton,
    BRow,
    modalConfirmacao,
    BCarousel,
    BCarouselSlide,
    imageZoom
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const quillEditorRef = ref(null)
    const cursorPosition = ref(0)
    const toast = useToast()
    const { proxy } = getCurrentInstance()
    const itemData = ref({ comentario: '' })
    const dadosItem = ref({
      nome: '',
      id: '',
    })

    const showModalSelecionarUsuario = () => {
      proxy.$bvModal.show('modal-selecionar-usuarios')
    }

    onMounted(() => {

      if (quillEditorRef.value) {
        const quill = quillEditorRef.value.quill

      }
    })

    const listaComentarios = ref([])
    const handleAtSymbol = () => {
      const quill = quillEditorRef.value.quill
      const totalCharacters = quill.getLength()
      cursorPosition.value = quill.getSelection().index

      showModalSelecionarUsuario()

    }

    const snowOption = {
      placeholder: 'Insira seu comentário aqui...',
      modules: {
        keyboard: {
          bindings: {
            atSymbol: {
              key: 50,
              shiftKey: true,
              handler: function (range, context) {
                const quill = this.quill
                quill.insertText(range.index, '@')
                quill.setSelection(range.index + 1)
                handleAtSymbol()
              },
            },
            customBackspace: {
              key: 8, // Backspace key
              handler: function (range, context) {
                const quill = this.quill
                const [line, offset] = quill.getLine(range.index)
                const textBeforeCursor = line.domNode.textContent.slice(0, offset)
                const match = textBeforeCursor.match(/@\w+([._\w\u00C0-\u024F]*)\b/)

                if (match && textBeforeCursor.endsWith(match[0])) {
                  const startIndex = textBeforeCursor.lastIndexOf(match[0])
                  quill.deleteText(startIndex, match[0].length)
                  quill.setSelection(startIndex)
                  return false
                } else {
                  quill.deleteText(range.index - 1, 1)
                  quill.setSelection(range.index - 1)
                  return false
                }
              }
            }
          },
        },
      },
      theme: 'snow',
    }

    const COMENTARIOS_APP_STORE_MODULE_NAME = 'app-comentarios'

    // Register module
    if (!store.hasModule(COMENTARIOS_APP_STORE_MODULE_NAME)) store.registerModule(COMENTARIOS_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMENTARIOS_APP_STORE_MODULE_NAME)) store.unregisterModule(COMENTARIOS_APP_STORE_MODULE_NAME)
    })

    const getComentarios = () => {
      store.dispatch('app-comentarios/fetchComentarios', { id: router.currentRoute.params.id })
          .then(response => {
            listaComentarios.value = response.data

          })
          .catch(error => {
            if (error.response.status === 404) {
              listaComentarios.value = undefined
            }
          })
    }
    getComentarios()

    const enviarComentario = () => {
      if (itemData.value.comentario === '' || itemData.value.comentario == null) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Comentário não pode ser vazio',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      store.dispatch('app-comentarios/store', {
        id: router.currentRoute.params.id,
        comentario: itemData.value.comentario,
      })
          .then(() => {
            itemData.value = { comentario: '' }
            listaComentarios.value = []
            getComentarios()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Comentários incluído no projeto',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
    }
    const excluirItem = ({ id }) => {
      store.dispatch('app-comentarios/delete', {
        id,
        projeto: router.currentRoute.params.id,
      })
          .then(() => {
            listaComentarios.value = []
            getComentarios()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Comentário excluído com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
    }

    return {
      itemData,
      snowOption,
      enviarComentario,
      listaComentarios,
      dadosItem,
      excluirItem,
      getComentarios,
      quillEditorRef,
      cursorPosition,
      toast,
      store
    }
  },
  data() {
    return {
      imagemModalSrc: '',
      imagensCarousel: [],
      processarImagens: false,
      status: false,
      imagemModalVisible: false,
      respostaComentario: {
        id: null,
        comentario: null
      },
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'imagem-modal') {
        this.imagemModalVisible = true
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'imagem-modal') {
        this.imagemModalVisible = false
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
    this.$root.$off('bv::modal::shown')
    this.$root.$off('bv::modal::hidden')
  },
  methods: {
    formatarDataEHora,
    convertStringToDate,
    mascaraDataBr,
    avatarText,

    prev() {
      if (!this.status) {
        this.$refs.myCarousel.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.myCarousel.next()
      }
    },
    handleKeydown(event) {
      if (this.imagemModalVisible) {
        if (event.key === 'ArrowLeft') {
          this.prev()
        } else if (event.key === 'ArrowRight') {
          this.next()
        }
      }
    },

    modalExcluir(item) {
      this.dadosItem.nome = item.usuario.name
      this.dadosItem.id = item.id.toString()

      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    visualizarFoto(imagens) {
      this.imagensCarousel = imagens
      this.$bvModal.show('imagem-modal')
    },

    processarComentario(comentario, grupoImagens) {
      const div = document.createElement('div')
      div.innerHTML = comentario

      const imagens = div.querySelectorAll('img')

      if (imagens.length > 0) {
        imagens.forEach((img, index) => {
          img.style.width = '150px'
          img.style.height = '100px'
          img.style.border = '4px solid #0087B4'
          img.style.margin = '3px'
          img.style.cursor = 'pointer'
          img.setAttribute('data-index', JSON.stringify(grupoImagens))
        })
      }

      const strippedStr = div.innerHTML.replace(/<[^>]+>/g, '')

      return div.innerHTML
    },
    tratarEventoUsuarioSelecionado(item) {
      const quill = this.$refs.quillEditorRef.quill
      const userName = item.name.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/ /g, '_')
      const userTag = `<strong>@${userName}</strong> `

      // Check if there is an @ symbol at the current position or one position before
      const textBeforeCursor = quill.getText(this.cursorPosition - 1, 1)
      if (textBeforeCursor === '@') {
        quill.deleteText(this.cursorPosition - 1, 1)
        this.cursorPosition -= 1
      }

      quill.clipboard.dangerouslyPasteHTML(this.cursorPosition, userTag)
      this.cursorPosition += userName.length + 1

      quill.setSelection(this.cursorPosition)

      quill.format('bold', false)
      this.$bvModal.hide('modal-selecionar-usuarios')
    },
    abrirModalResponder(item) {
      this.respostaComentario.id = item.id
      this.$bvModal.show('modal-responder')

    },
    handleImageClick(event) {
      const imagensGrupo = JSON.parse(event.target.getAttribute('data-index'))

      if (imagensGrupo) {
        this.visualizarFoto(imagensGrupo)
      }
    },
    enviarResposta() {

      if (this.respostaComentario && this.respostaComentario.id === '' && this.respostaComentario.comentario == null) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Comentário não pode ser vazio',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.store.dispatch('app-comentarios/responderComentario', {
        id: this.respostaComentario.id,
        comentario: this.respostaComentario.comentario,
      })
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Resposta no comentario enviada',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('modal-responder')
            this.getComentarios()
            this.respostaComentario.comentario = ''
            this.respostaComentario.id = ''
          })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.text-gray-200 {
  color: #d7d7d7;
}

.descricao-comentario * {
  width: 100%;
}

.carousel {
  text-align: center;
}

.modal__comentarios .modal-content {
  max-height: 500px;
}

.modalSelecionarUsuarios {
  .modal-content {
    height: 500px;
  }
}

</style>
